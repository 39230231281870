<template>
    <v-layout column>
        <v-flex>
            <label style="color: rgba(0,0,0,.54);font-size: 16px">Card</label>
        </v-flex>
        <v-flex>
            <div>
                <div ref="elem" class="card-element"></div>
                <!--id="card-element"-->
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
    import {Stripe_loader} from '@/module/service/lazyLoad'

    export default {
        name: "StripeCard",
        methods: {
            initCard() {
                Stripe_loader().then(({stripe,elements}) => {
                    this.stripe = stripe
                    this.card = elements.create('card', {})
                    this.card.mount(this.$refs.elem)
                    this.card.on('change', (event) => {
                        this.$emit('tokenChange', event)
                    })
                })
            },
        },
        mounted() {
            this.initCard()
        },
        beforeDestroy() {
            if (this.card) {
                this.card.destroy()
            }
        }
    }
</script>

<style>
    .card-element {
        padding: 10px;
        border: 1px solid darkgrey;
        border-radius: 5px;
        margin-top: 6px;
    }
</style>
