<template>
    <v-container pa-0>
        <v-layou column>
            <v-flex style="min-height: 172px">
                <v-list>
                    <template v-if="!loading">
                        <v-radio-group v-model="currentCreditCard">
                            <v-list-tile v-if="creditCardList.length" avatar>
                                <v-list-tile-action>
                                    <v-radio value="exsitCredit"></v-radio>
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-layout fill-height>
                                        <v-flex shrink d-flex align-center>
                                            <i :class="classes" style="font-size: 25px"
                                               aria-hidden="true"></i>
                                        </v-flex>
                                        <v-flex grow d-flex align-center>
                                            <span class="pl-3 text-truncate">Credit Card Ending in {{creditCardList[0].last4}}</span>
                                        </v-flex>
                                    </v-layout>
                                </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile>
                                <v-list-tile-action>
                                    <v-radio value="newCredit"></v-radio>
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    Pay with a New Card
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-radio-group>
                    </template>
                    <v-list-tile class="my-3" v-if="loading">
                        <spin></spin>
                    </v-list-tile>
                </v-list>
            </v-flex>
            <v-flex xs12 sm10 md6 style="padding-bottom: 20px;" v-show="currentCreditCard === 'newCredit'">
                <v-layout style="background-color: #fafafa;padding: 10px 18px 0px 18px;border-radius: 8px;" column>
                    <v-flex>
                        <StripeCard ref="stripecard" @tokenChange="cardChange"></StripeCard>
                    </v-flex>
                    <v-flex>
                        <span v-if="cardEvent.error" :style="{color: $vuetify.theme.error}">{{cardEvent.error.message}}</span>
                    </v-flex>
                    <v-flex xs10>
                        <v-checkbox v-model="isDefault"
                                    :disabled="!!creditCardList.length"
                                    label="As default">
                        </v-checkbox>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex v-if="invoiceDetail.status === 'Unpaid'">
                <v-btn style="border-radius: 4px" color="blue" dark @click="pay" :loading="btnLoading">
                    <v-icon left>credit_card</v-icon>
                    Pay Now
                </v-btn>
            </v-flex>
        </v-layou>
    </v-container>
</template>

<script>
    import StripeCard from '@/components/StripeCard'
    import Spin from '@/components/Spin'
    // /payment/1db602d3dbfe487c9ee8c06b8084b102
    export default {
        name: "CreditCardBox",
        components: {
            StripeCard,
            Spin
        },
        props: ['invoiceDetail'],
        data() {
            return {
                btnLoading: false,
                isDefault: false,
                loading: false,
                currentCreditCard: '',
                creditCardList: [],
                cardEvent: {}
            }
        },
        computed: {
            classes() {
                let flag = !!this.creditCardList[0].brand
                return flag ? ['fa', `fa-cc-${this.creditCardList[0].brand.toLowerCase()}`] : ['fa', 'fa-credit-card']
            },
        },
        methods: {
            cardChange(event) {
                if (event) {
                    this.cardEvent = event
                }
            },
            initCreditCard() {
                this.loading = true
                this.$http({
                    method: 'get',
                    url: `/credit-cards/?client_id=${this.$store.state.client.clientInfo.id}`,
                }).then(res => {
                    this.loading = false
                    this.creditCardList = res.results
                    this.currentCreditCard = this.creditCardList.length ? 'exsitCredit' : 'newCredit'
                }).catch(() => {
                    this.loading = false
                    this.$message.error('Oops, it failed to get your Credit Card information. Please contact us at support@cloudclusters.io for this issue.')
                })
            },
            pay() {
                this.btnLoading = true
                if (this.currentCreditCard === 'newCredit') {
                    if (!this.cardEvent.complete||this.cardEvent.error) {
                        this.btnLoading = false
                        return
                    }
                    const stripeComp = this.$refs.stripecard
                    stripeComp.stripe.createToken(stripeComp.card).then(({token,error}) => {
                        if (token) {
                            const obj = {
                                stripe_token: token.id,
                                set_as_default: this.isDefault,
                                invoice_uuid: this.$route.params.invoiceId
                            }
                            this.payWithNewCard(obj);
                        } else if (error) {
                            this.$message.error(error.message)
                            this.btnLoading = false
                        }
                    }).catch(err => {
                        this.btnLoading = false
                        this.$message.error(err.detail)
                    })
                } else {
                    this.payWithExsitCard()
                }

            },
            payWithNewCard(obj) {
                this.$http({
                    method: 'post',
                    url: `/payments/stripe-pay-with-new-card/`,
                    data: obj
                }).then(() => {
                    // this.$router.push('/applications')
                    this.btnLoading = false
                    this.$emit('success')
                    // this.$message.success('You made the payment successfully. Thank you !');
                }).catch((e) => {
                    this.btnLoading = false
                    this.$message.error(e.detail);
                    // this.$message.error('Oops, it failed to get your payment. Please contact us at support@cloudclusters.io for this issue.');
                })
            },
            payWithExsitCard() {
                this.$http({
                    method: 'post',
                    url: `/payments/stripe-pay-with-existing-card/`,
                    data: {
                        invoice_uuid: this.$route.params.invoiceId,
                        credit_card_uuid: this.creditCardList[0].uuid
                    }
                }).then(() => {
                    // this.$router.push('/applications')
                    this.btnLoading = false
                    this.$emit('success')
                    // this.$message.success('You made the payment successfully. Thank you !')
                }).catch((e) => {
                    this.btnLoading = false
                    this.$message.error(e.detail);
                    // this.$message.error('Please contact support at billing@cloudclusters.io or contact your bank (card issuer) for more information.')
                })
            }
        },
        created() {
            this.initCreditCard()
        }
    }
</script>

<style lang="scss">
</style>
