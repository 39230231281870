<template>
    <v-container v-if="ready" class="payment-box-panel" style="background-color: #fff">
        <v-layout wrap class="panel-wrapper py-3 px-5 mb-3 pb-1" >
            <v-flex>
                <v-layout wrap>
                    <v-flex xs12>
                        <h1>Order Details</h1>
                    </v-flex>
                    <v-flex xs12 md8>
                        <p>{{formatDate(invoice.due_date)}} - {{formatDate(invoice.end_date)}}</p>
                    </v-flex>
                    <v-flex xs12 md4>
                        Order # {{invoice.id}}
                    </v-flex>
                </v-layout>
                <div class="order-details">
                    <v-layout wrap class="border-bottom pa-3">
                        <v-flex xs4><h3>Billing To</h3></v-flex>
                        <v-flex xs4><h3>Payment Method</h3></v-flex>
                        <v-flex xs4><h3>Summary</h3></v-flex>
                    </v-layout>
                    <v-layout wrap class="border-bottom pa-3 pb-1">
                        <v-flex xs4>
                            <p v-html="invoice.invoiced_to"></p>
                        </v-flex>
                        <v-flex xs4>
                            <p>{{invoice.payment_method}}</p>
                        </v-flex>
                        <v-flex xs4>
                            <v-layout wrap>
                                <v-flex xs6>Product Name</v-flex>
                                <v-flex xs6>{{invoice.plan}} {{invoice.product}}</v-flex>
                                <v-flex xs6>Sub Total</v-flex>
                                <v-flex xs6>$ {{invoice.sub_total}} </v-flex>
								<v-flex xs6>Credits</v-flex>
								<v-flex xs6>$ {{invoice.credit}} </v-flex>
                                <v-flex xs6>Tax</v-flex>
                                <v-flex xs6>$ {{invoice.tax_fee}} </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-layout wrap class="pa-3">
                        <v-flex style="line-height: 36px;" xs3>Total</v-flex>
                        <v-flex xs3>$ <span style="color: orangered;font-size: 24px;font-weight: 600">{{invoice.total}}</span></v-flex>
                    </v-layout>
                </div>
            </v-flex>
        </v-layout>
        <v-layout column class="panel-wrapper py-3 px-5">
            <!--<v-flex>-->
                <!--<v-subheader class="panel-title">Choose a Payment Method</v-subheader>-->
            <!--</v-flex>-->
            <v-flex>
                <v-layout wrap>
                    <v-flex xs6 md3>
                        <v-card class="payment-method py-3 mt-2" :class="{active:payment=== 'paypal' }"
                                @click="changeMethod('paypal')">
                            <h3 class="text-xs-center">PayPal</h3>
                            <div class="text-xs-center">
                                <i class="fa fa-cc-paypal"></i>
                            </div>
                        </v-card>
                    </v-flex>
                    <v-flex xs6 md3>
                        <v-card class="payment-method py-3 mt-2" :class="{active:payment=== 'creditcard' }"
                                @click="changeMethod('creditcard')">
                            <h3 class="text-xs-center">Credit Card</h3>
                            <div class="text-xs-center">
                                <i class="fa fa-credit-card"></i>
                            </div>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex>
                <components :is="mod" ref="mod" :invoice-detail="invoice" @success="successHandler"></components>
            </v-flex>
        </v-layout>
        <SuccessBox ref="successBox"></SuccessBox>
    </v-container>
</template>
<script>

    import CreditCardBox from "./_source/CreditCardBox.vue"
    import PayPalBox from "./_source/PayPalBox.vue"
    import SuccessBox from '../../components/successBox/SuccessBox.vue'
    import {formatDate} from '@/module/utils/date'

    const typeArr = ['paypal', 'creditcard']
    ///payments/paypal-billing-pay/

    export default {
        name: "payment",
        components: {SuccessBox},
        data() {
            return {
                ready: false,
                payment: typeArr[0],
                invoice: {}
            }
        },
        computed: {
            mod() {
                return this.payment === typeArr[0] ? PayPalBox : CreditCardBox
            }
        },
        methods: {
            formatDate,
            successHandler() {
                this.$refs.successBox.open()
                this.getInvoice()
            },
            changeMethod(payment) {
                this.payment = payment
            },
            getInvoice() {
                return this.$http.get(`/invoices/${this.invoiceId}/`).then(res => {
                    this.invoice = res;
                    this.payment = res.payment_method === 'Credit Card'?typeArr[1]:typeArr[0]
                    this.ready = true;
                }).catch(e => {
                    this.$message.error(e.detail)
                });
            }
        },
        created() {
            this.invoiceId = this.$route.params.invoiceId;
            this.getInvoice();
        }
    }
</script>

<style lang="scss">
    .payment-box-panel {
        .payment-method {
            transform: scale(0.94);
            &.active {
                transform: scale(1.06);
                background: #3f51b5;
                color: rgba(255, 255, 255, 0.7);
                .fa {
                    color: rgba(255, 255, 255, 0.7);
                }

            }
            .fa {
                color: rgba(0, 0, 0, 0.5);
                font-size: 50px;
            }
        }
    }

    .order-details {
        border: solid 1px #dddddd;
        border-radius: 4px;
    }

    .border-bottom {
        border-bottom: solid 1px #dddddd;
    }
</style>
