var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c("v-flex", [
        _c(
          "label",
          { staticStyle: { color: "rgba(0,0,0,.54)", "font-size": "16px" } },
          [_vm._v("Card")]
        )
      ]),
      _c("v-flex", [
        _c("div", [_c("div", { ref: "elem", staticClass: "card-element" })])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }