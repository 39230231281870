<template>
    <v-dialog v-model="dialog" persistent max-width="600px" no-click-animation>
        <v-form v-model="valid" ref="form" lazy-validation>
            <v-card>
                <v-card-text>
                    <v-container pa-0>
                        <v-layout align-center justify-center>
                            <v-flex shrink align-self-center>
                                <v-img width="150" :src="imgstr"></v-img>
                            </v-flex>
                        </v-layout>
                        <v-layout column mt-4>
                            <!--<v-flex align-self-center>-->
                                <!--<b>The payment ID is :</b> 22644-->
                            <!--</v-flex>-->
                            <v-flex align-self-center mt-2>
                                <b >Success!</b>
                            </v-flex>
                            <v-flex  mt-4 mx-6>
                                {{text[from]}}
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!from" class="text-none" color="primary" flat>
                        <router-link :to="`/invoice-detail/${invoiceId}`">Details</router-link>
                    </v-btn>
                    <v-btn class="text-none" color="primary" flat>
                        <router-link to="/applications">Applications</router-link>
                    </v-btn>
                    <v-btn class="text-none" v-if="!from" color="primary" @click="close">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import imgstr from './shakehand.png'
    export default {
        name: "SuccessBox",
        mixins: [loadingMixin],
        data() {
            return {
                imgstr,
                from: 0,
                text: [
                    'The invoice is paid. Thank you for your business!',
                    'Thank you for your order. Your request is being processed now,which may take 1-3 minu' +
                    'tes. We really appreciate your patience. Please do not hesitate to contact us if you have any question.'
                ]
            }
        },
        methods: {
            open(from) {
                if (from) {
                    this.from = from
                }
                this.dialog = true
            }
        },
        created(){
            this.invoiceId =  this.$route.params.invoiceId
        }
    }
</script>

<style lang="scss">

</style>
