import {app_key} from '@/module/constant.js'

var loader = function (key, url) {
    if (window[key]) {
        return Promise.resolve(window[key])
    } else {
        let d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        g.type = 'text/javascript';
        g.async = true;
        g.defer = true;
        g.src = url;
        s.parentNode.insertBefore(g, s);
        return new Promise((resolve) => {
            if (g.addEventListener) {
                g.addEventListener('load', function () {
                    resolve(window[key])
                }, false);
            } else if (g.attachEvent) {
                g.attachEvent('onreadystatechange', function () {
                    let target = window.event.srcElement;
                    if (target.readyState == 'loaded') {
                        resolve(window[key])
                    }
                });
            }
        })
    }
}


const CKEDITOR_loader = function () {
    let url = "https://cdn.ckeditor.com/4.14.1/standard-all/ckeditor.js";
    let key = 'CKEDITOR'
    return loader(key, url)
}

let stripeP = null
const Stripe_loader = function () {
    let url = "https://js.stripe.com/v3/";
    let key = 'Stripe'
    if (!stripeP) {
        stripeP = loader(key, url).then(Stripe => {
            const stripe = Stripe(app_key)
            const elements = stripe.elements({
                locale: 'US'
            })
            return Promise.resolve({stripe,elements})
        })
    }
    return stripeP
}
const PayPal_loader = function () {
    let url = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL}&vault=true`;
    let key = 'paypal'
    return loader(key, url)
}

export {CKEDITOR_loader, Stripe_loader, PayPal_loader}
