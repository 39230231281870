<template>
    <v-container pa-0>
        <v-layout column>
            <v-flex>
                <v-list>
                    <v-radio-group v-model="selectedPaypal">
                        <v-list-tile v-for="(item ,idx) in paypal" :key="item" avatar>
                            <v-list-tile-action>
                                <v-radio :value="idx"></v-radio>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                <v-list-tile-title v-text="item"></v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-radio-group>
                </v-list>
            </v-flex>
            <v-flex v-if="invoiceDetail.status === 'Unpaid'">
                <div v-show="selectedPaypal === 0" style="height: 38px;">
                    <div id="checkout" style="width: 60px;"></div>
                </div>
                <div v-show="selectedPaypal === 1" style="height: 38px;">
                    <div id="subscription" style="width: 60px;"></div>
                </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    // status === 'Unpaid'
    import {PayPal_loader} from '@/module/service/lazyLoad'

    export default {
        name: "PayPal",
        props: ['invoiceDetail'],
        data() {
            return {
                selectedPaypal: 0,
            }
        },
        computed : {
            paypal (){
                if(this.invoiceDetail.type==='Billing Cycle' && this.invoiceDetail.duration<=12){
                    return ['PayPal Checkout', 'PayPal Subscription']
                }else{
                    return ['PayPal Checkout']
                }
            }
        },
        methods: {
            renderCheckout() {
                let self = this;
                window.paypal.Buttons({
                    style: {
                        layout: 'horizontal',
                        height: 36,
                        color: 'blue',
                        shape: 'rect',
                        label: 'paypal',
                        tagline: false
                    },
                    createOrder: function (data, actions) {
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: self.invoiceDetail.total
                                }
                            }]
                        });
                    },
                    onApprove: function (data, actions) {
                        return actions.order.capture().then(function (details) {
                            return self.$http.post(`/payments/${self.invoiceId}/paypal-complete/`, {
                                transtaction_id: details.purchase_units[0].payments.captures[0].id,
                                payer :details.payer
                            }).then(() => {
                                self.$emit('success')
                            })
                        });
                    },
                    onError: function (err) {
                        console.log(err)
                        // alert(err)
                    }
                }).render('#checkout')
            },
            renderSubscription() {
                let self = this;
                window.paypal.Buttons({
                    style: {
                        layout: 'horizontal',
                        height: 36,
                        color: 'blue',
                        shape: 'rect',
                        label: 'paypal',
                        tagline: false
                    },
                    createSubscription: function (data, actions) {
                        return self.$http.post(`/payments/${self.invoiceId}/paypal-billing-plan/`).then((res) => {
                            return actions.subscription.create({
                                'plan_id': res
                            })
                        })
                    },
                    onApprove: function (data) {
                        self.$http.post(`/payments/${self.invoiceId}/paypal-subscription-complete/`, {
                            subscription_id: data.subscriptionID,
                        }).then(() => {
                            self.$emit('success')
                        })
                    },
                    onError: function (err) {
                        console.log(err)
                        // alert(err)
                    }
                }).render('#subscription')
            }
        },
        mounted() {
            if (this.invoiceDetail.status === 'Unpaid') {
                this.pp.then(() => {
                    this.renderCheckout()
                    this.renderSubscription()
                })
            }
        },
        created() {
            this.pp = PayPal_loader()
            this.invoiceId = this.$route.params.invoiceId;
            console.log(this.invoiceDetail)
        }
    }
</script>

<style lang="scss">

</style>
