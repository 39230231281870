var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "pa-0": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "align-center": "", "justify-center": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { shrink: "", "align-self-center": "" } },
                            [
                              _c("v-img", {
                                attrs: { width: "150", src: _vm.imgstr }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { column: "", "mt-4": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { "align-self-center": "", "mt-2": "" } },
                            [_c("b", [_vm._v("Success!")])]
                          ),
                          _c("v-flex", { attrs: { "mt-4": "", "mx-6": "" } }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.text[_vm.from]) +
                                "\n                        "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  !_vm.from
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: { color: "primary", flat: "" }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: { to: "/invoice-detail/" + _vm.invoiceId }
                            },
                            [_vm._v("Details")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: { color: "primary", flat: "" }
                    },
                    [
                      _c("router-link", { attrs: { to: "/applications" } }, [
                        _vm._v("Applications")
                      ])
                    ],
                    1
                  ),
                  !_vm.from
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: { color: "primary" },
                          on: { click: _vm.close }
                        },
                        [_vm._v("Close")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }